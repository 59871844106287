
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

































































































.modal::v-deep {
  .animation-content {
    max-width: 960px !important;
  }

  .modal-card {
    max-width: 650px !important;

    .modal-card-foot {
      padding: 10px;
    }
  }
}
