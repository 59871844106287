
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        





































































































::v-deep {
    .sort-icon {
        line-height: 1.5;
    }

    .v-popover {
        & + span {
            .sort-icon {
                line-height: 2.4;
            }
        }
    }

    ul {
        li {
            &:not(:first-child) {
                padding-top: 0.2em;
            }

            div {
                .flag-icon {
                    margin-right: 0.4em;
                }
            }
        }
    }

    table {
        thead, tbody {
            background-color: white;
        }

        thead {
            top: 0;

            tr {
                &:first-child {
                    th {
                        background-color: #f7f7f7;
                        padding: 0;
                        font-size: 1rem;

                        > div {
                            display: flex;
                            padding: 20px;
                            align-items: center;
                            justify-content: space-between;

                            label {
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                &.is-subheading {
                    th {
                        border-top: 1px solid #EDEDED !important;
                        border-bottom: 1px solid #EDEDED !important;
                        background: $white;
                        font-size: 1rem;
                        color: $light;
                    }
                }
            }
        }
    }
  .card {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}

.icon-btn {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
}
