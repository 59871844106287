
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        














































































































.dropdown {
    $self: &;

    ::v-deep {
        #{ $self }-content {
            #{ $self }-item.is-active {
                background-color: $primary;
            }
        }
    }
}

.button {

    ::v-deep {
        .icon {
            display: none;
        }
    }
}

.control {
    ::v-deep {
        input {
            border: none;
            height: auto;
            pointer-events: none;
            background-color: transparent;
            width: 56px;
            text-align: center;
            padding-right: calc(0.75rem - 1px) !important;
            caret-color: transparent;
        }
    }
}
