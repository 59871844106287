
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        


































































































.tile-item {
    height: 100%;
    cursor: pointer;

    .is-child {
        justify-content: space-between;
    }

    &-content {
        min-height: 1.5em;
    }

    &-footer {
        .tippy:not(:last-child) {
            margin-right: 0.8em;
        }
    }
}
